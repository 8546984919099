export function logger(
  msg: string,
  origin?: string,
  data?: any,
  color?: string
) {
  const mode = process.env.NODE_ENV;

  const blacklist = [
    "indexedDB",
    "assets-handler",
    "IkkyoEditor",
    "XREditor",
    "useScenes",
    "useTours",
    "XREnviroment",
    // "IkkyoActionManager",
    "XRTour",
  ];

  // if is in black list make color grey
  if (blacklist.includes(origin)) {
    data = "";
    color = "grey";
  }

  if (mode === "development") {
    // colored console log
    console.log(
      `%c[${origin || "unknown"}]: ${msg}`,
      "color: " + (color || "grey"),
      data || ""
    );
  }
}
