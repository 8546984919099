import { createTheme } from "@mui/material";

declare module "@mui/material/styles" {
  interface Theme {}

  // allow configuration using `createTheme`
  interface ThemeOptions {}

  interface PaletteOptions {
    ikkyo_colors?: {
      main: string;
      one: string;
      two: string;
      gray: string;
      attention: string;
      red: string;
      green: string;
      blue: string;
      transparent1: string;
      transparent2: string;
      transparent3: string;
      transparent: string;
      darkText: string;
      whiteText: string;
      shaddow: string;
      light: string;
    };
  }

  interface Palette {
    ikkyo_colors: {
      main: string;
      one: string;
      two: string;
      gray: string;
      attention: string;
      red: string;
      green: string;
      blue: string;
      transparent1: string;
      transparent2: string;
      transparent3: string;
      transparent: string;
      darkText: string;
      whiteText: string;
      shaddow: string;
      light: string;
    };
  }
}

const theme = createTheme({
  palette: {
    primary: { main: "#405463" },
    secondary: { main: "#00d646" },
    background: { default: "#e0e0e0" },
    ikkyo_colors: {
      main: "#E5E5E5",
      one: "#e0e0e0",
      two: "#000000",
      gray: "#555770",
      green: "#00d646",
      red: "#ff3333",
      blue: "#3d85c6",
      attention: "#fa7406",
      transparent1: "#b3b3b334",
      transparent2: "#e0e0e081",
      transparent3: "#4054638d",
      transparent: "#ffffff0",
      darkText: "#000000",
      whiteText: "#fdf9f9",
      shaddow: "#bebebe",
      light: "#ffffff",
    },
  },
});

theme.typography.body1 = {
  fontFamily: "Ubuntu-Light, sans-serif",
  "@media (min-width:600px)": {
    fontSize: "1.2rem",
  },
  "@media (min-width:700px)": {
    fontSize: "1.2rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1rem",
  },
};

theme.typography.h3 = {
  fontFamily: "Ubuntu-Light, sans-serif",
  "@media (min-width:600px)": {
    fontSize: "1.5rem",
  },
  "@media (min-width:300px)": {
    fontSize: "2.4rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2.4rem",
  },
};

theme.typography.h5 = {
  fontFamily: "Ubuntu-Light, sans-serif",
  "@media (min-width:600px)": {
    fontSize: "1rem",
  },
  "@media (min-width:300px)": {
    fontSize: "1.4rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.4rem",
  },
};

theme.typography.button = {
  fontFamily: "Ubuntu-Light, sans-serif",
  "@media (min-width:600px)": {
    fontSize: "0.5rem",
  },
  "@media (min-width:300px)": {
    padding: "5px 10px",
    fontSize: "0.8rem",
  },
  [theme.breakpoints.up("md")]: {
    padding: "5px 40px",
    fontSize: "1rem",
  },
};

theme.typography.overline = {
  fontFamily: "Ubuntu-Bold, sans-serif",
  "@media (min-width:600px)": {
    fontSize: "0.5rem",
  },
  "@media (min-width:300px)": {
    fontSize: "1rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1rem",
  },
};

export default theme;
