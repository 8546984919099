import { StrictMode, Suspense } from "react";
import { createRoot } from "react-dom/client";
import { ApolloProvider } from "@apollo/client";
import reportWebVitals from "./reportWebVitals";
import apolloClient from "./apolloClient";

import { ThemeProvider } from "@mui/material/";

import App from "./App";

import "./index.css";
import "./i18n/config";

import { UserContextProvider } from "./plumbing/context/UserContext";
import LoadingScreen from "./components/LoadingScreen";
import { CustomMetaData } from "./components";
import theme from "./theme";

const container = document.getElementById("root");

const root = createRoot(container);
root.render(
  <StrictMode>
    <Suspense fallback={<LoadingScreen isOpen />}>
      <ThemeProvider theme={theme}>
        <ApolloProvider client={apolloClient}>
          <UserContextProvider>
            <CustomMetaData />
            <App />
          </UserContextProvider>
        </ApolloProvider>
      </ThemeProvider>
    </Suspense>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
